import config from '@ir-engine/common/src/config'
import React from 'react'

export const Redirect = () => {
  const path = new URLSearchParams(location.search)?.has('error')
    ? `/concierge/dashboard${location.search}`
    : '/concierge/dashboard'
  const url = `${config.client.clientUrl}${path}`

  window.location.href = url
  return <></>
}

export default Redirect
