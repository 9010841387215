import type { ProjectConfigInterface } from '@ir-engine/projects/ProjectConfigInterface'

const config: ProjectConfigInterface = {
  onEvent: './projectEventHooks.ts',
  thumbnail: '/static/ir-engine.png',
  webappInjection: () => import('./webappInjection'),
  routes: {
    '/': {
      component: () => import('./components/pages/Redirect'),
      props: {
        exact: true
      }
    },
    '/dashboard': {
      component: () => import('./components/pages/DashboardPage'),
      props: {
        exact: true
      }
    },
    '/signin': {
      component: () => import('./components/pages/Onboarding/SignInPage'),
      props: {
        exact: true
      }
    },
    '/signup': {
      component: () => import('./components/pages/Onboarding/SignInPage'),
      props: {
        exact: true
      },
      componentProps: {
        isSignUp: true
      }
    },
    '/customerdetails': {
      component: () => import('./components/pages/Onboarding/CustomerDetailsPage'),
      props: {
        exact: true
      }
    },
    '/accountdetails': {
      component: () => import('./components/pages/Onboarding/AccountDetailsPage'),
      props: {
        exact: true
      }
    },
    '/studio': {
      component: () => import('./components/pages/Studio/StudioPage')
    },
    '/location': {
      component: () => import('./components/pages/Location/LocationPage')
    }

    // '/get-started': {
    //   component: () => import('./components/pages/GetStartedPage'),
    //   props: {
    //     exact: true
    //   }
    // },
    // '/pricing': {
    //   component: () => import('./components/pages/PricingPage'),
    //   props: {
    //     exact: true
    //   }
    // },
    // '/subscriptions': {
    //   component: () => import('./components/pages/SubscriptionsPage'),
    //   props: {
    //     exact: true
    //   }
    // },
  },
  services: './services/services.ts',
  databaseSeed: './services/seeder-config.ts'
}

export default config
